import React from "react"
import Layout from "../components/layoutLogin"
import EndProcessMessage from "../components/endProcessMessage"
import SEO from "../components/seo"

const EndForgotPasswordPage = () => (
  <Layout>
    <SEO title="Recuperar contraseña" />
    <EndProcessMessage message={'Te hemos enviado instrucciones para que puedas recuperar tu contraseña.'}/>
  </Layout>
)

export default EndForgotPasswordPage
