/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Header from "../header"
import * as styles from "./styles.module.css"

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <div className={styles.background}>
        <div className={styles.triangle} href="#1"></div>
        <div className={styles.triangle} href="#2"></div>
      </div>
      <div className={styles.mainContainer}>
        <main>
          {children}
        </main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
