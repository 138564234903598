import React from 'react'
import * as styles from './styles.module.css'

const EndProcessMessage = ({message}) => (
  <div className={styles.container}>
    <div className={styles.box}>
      <div>
        <img src={'https://picsum.photos/450/450'}/>
      </div>
      <div className={styles.content}>
        <h1>Por favor revisa tu correo</h1>
        <p>{message}</p>
        <p>El correo puede tardar unos minutos en llegar.</p>
      </div>
    </div>
  </div>
)
export default EndProcessMessage